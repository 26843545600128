import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46')
];

export const server_loads = [2,3];

export const dictionary = {
		"/": [~6],
		"/(studio)/studio/[...catchall]": [7],
		"/[lang]/(app)": [~8,[2,3],[,4]],
		"/[lang]/(app)/(tjenester)/aapningstider": [~12,[2,3,5],[,4]],
		"/[lang]/(app)/(demo)/all": [~9,[2,3],[,4]],
		"/[lang]/(app)/artikkel": [~42,[2,3],[,4]],
		"/[lang]/(app)/artikkel/[slug]": [~43,[2,3],[,4]],
		"/[lang]/(app)/(tjenester)/barnehage": [~13,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/barnehage/[slug]": [~14,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/bolig": [~15,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/bolig/(studentbolig)/[slug]": [~16,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/helse": [~17,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/helse/helsestasjon": [~18,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/helse/lege": [~19,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/helse/tannlege": [~20,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/hjelp-og-dialog": [~21,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/hjelp-og-dialog/ofte-stilte-sporsmal": [~22,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/karriere": [~23,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/karriere/externship": [~24,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/karriere/externship/annonser": [~25,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/kurs": [~26,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/kurs/affy/[kursid]": [~27,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/kurs/ibooking/[eventid]": [~28,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/mat-og-drikke": [~29,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/mat-og-drikke/spisested/[slug]": [~30,[2,3,5],[,4]],
		"/[lang]/(app)/om-sammen": [~44,[2,3],[,4]],
		"/[lang]/(app)/om-sammen/[slug]": [~45,[2,3],[,4]],
		"/[lang]/(app)/(tjenester)/psykisk-helse": [~31,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/psykisk-helse/(bookingtjeneste)/[slug]": [~32,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/psykisk-helse/(bookingtjeneste)/[slug]/[gruppe]": [~33,[2,3,5],[,4]],
		"/[lang]/(app)/sok": [46,[2,3],[,4]],
		"/[lang]/(app)/(tjenester)/studentgoder": [~34,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/studentliv": [~35,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/studentliv/[slug]": [~36,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/trening": [~37,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/trening/bli-medlem-bedrift": [~39,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/trening/bli-medlem": [~38,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/trening/finn-medlemskap": [~40,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/trening/senter/[slug]": [~41,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/[omraderessurs]": [~10,[2,3,5],[,4]],
		"/[lang]/(app)/(tjenester)/[omraderessurs]/[slug]": [~11,[2,3,5],[,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';