import { PUBLIC_SENTRY_DSN, PUBLIC_SENTRY_ENABLED, PUBLIC_SENTRY_ENVIRONMENT } from '$env/static/public'
import { handleErrorWithSentry } from '@sentry/sveltekit'
import * as Sentry from '@sentry/sveltekit'

Sentry.init({
  dsn: PUBLIC_SENTRY_DSN,
  enabled: PUBLIC_SENTRY_ENABLED === 'true',
  tracesSampleRate: 1.0,
  environment: PUBLIC_SENTRY_ENVIRONMENT,
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
